import types from './types';

export const listIvrVariables = (payload) => ({
  type: types.LIST_IVR_VARIABLES,
  payload,
});
export const listIvrVariablesSuccess = (payload) => ({
  type: types.LIST_IVR_VARIABLES_SUCCESS,
  payload,
});

export const searchIvrVariables = ({ botId, queryParams }) => ({
  type: types.SEARCH_IVR_VARIABLES,
  payload: { botId, queryParams },
});
export const searchIvrVariablesSuccess = (payload) => ({
  type: types.SEARCH_IVR_VARIABLES_SUCCESS,
  payload,
});
export const searchIvrVariablesFailure = (payload) => ({
  type: types.SEARCH_IVR_VARIABLES_FAILURE,
  payload,
});
export const resetSearchIvrVariables = () => ({
  type: types.RESET_SEARCH_IVR_VARIABLES,
});

export const createIvrVariable = (payload, callback) => ({
  type: types.CREATE_IVR_VARIABLE,
  payload: { ...payload, callback },
});
export const createIvrVariableSuccess = (payload) => ({
  type: types.CREATE_IVR_IVR_VARIABLE_SUCCESS,
  payload,
});

export const updateIvrVariable = (payload, callback) => ({
  type: types.UPDATE_IVR_VARIABLE,
  payload: { ...payload, callback },
});
export const updateIvrVariableSuccess = (payload) => ({
  type: types.UPDATE_IVR_VARIABLE_SUCCESS,
  payload,
});

export const deleteIvrVariable = ({ id }, callback) => ({
  type: types.DELETE_IVR_VARIABLE,
  payload: { id, callback },
});
export const deleteIvrVariableSuccess = ({ id }) => ({
  type: types.DELETE_IVR_VARIABLE_SUCCESS,
  payload: { id },
});

export const decryptIvrVariable = ({ botId, value }, callback) => ({
  type: types.DECRYPT_IVR_VARIABLE,
  payload: { botId, value, callback },
});
