import { takeLatest, call, put, all } from 'redux-saga/effects';
import i18n from '../../../translations/i18n';

import api from '../../../services/api';

import { toast } from '../../../components/CustomToast';

import {
  commonLoadingStart,
  commonLoadingFinish,
  commonChangingFinish,
} from '../common/actions';
import * as actions from './actions';
import * as authActions from '../auth/actions';
import types from './types';

export function* fetchCompany(action) {
  try {
    const { payload } = action;

    yield put(commonLoadingStart());

    const { data } = yield call(api.get, `v3/companies/${payload.companyId}`);

    yield put(actions.fetchCompanySuccess(data));
  } catch (e) {
    toast.error(i18n.t('error.error'), i18n.t('error.error_fetch_company'));
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* fetchCompanies() {
  try {
    yield put(commonLoadingStart());

    const response = yield call(api.get, 'v3/companies');

    yield put(actions.fetchCompaniesSuccess(response.data));
  } catch (e) {
    toast.error(i18n.t('error.error'), i18n.t('error.error_fetch_companies'));
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* updateCompany(action) {
  try {
    yield put(commonLoadingStart());

    const { payload } = action;

    const { data } = yield call(
      api.put,
      `v3/companies/${payload._id}`,
      action.payload
    );

    yield put(actions.updateCompanySuccess(data));
    yield put(actions.fetchCompanies(data._id));
    yield put(authActions.updateLoggedUserSelectedCompany(data));

    toast.success(
      i18n.t('success.success'),
      i18n.t('success.success_upadte_company')
    );
  } catch (e) {
    toast.error(i18n.t('error.error'), i18n.t('error.error_edit_company'));
  } finally {
    yield put(commonChangingFinish());
    yield put(commonLoadingFinish());
  }
}

export function* changePlanCompany(action) {
  try {
    yield put(commonLoadingStart());

    const { payload } = action;

    const { data } = yield call(api.post, `v3/companies/updatePlan`, payload);

    yield call(api.post, `v3/accounts/plans`, {
      date: new Date(),
      companyId: payload.companyId,
    });

    yield put(actions.changePlanCompanySuccess(data));
    toast.success(i18n.t('success.success'), i18n.t('success.updated_plan'));
  } catch (e) {
    toast.error(i18n.t('error.error'), i18n.t('error.error_change_plan'));
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* createAdditionalMessage(action) {
  try {
    yield put(commonLoadingStart());

    const { payload } = action;

    const { data } = yield call(
      api.post,
      'v3/companies/addAdditionalMessage',
      payload
    );

    yield put(actions.createAdditionalMessageSuccess(data));
    toast.success(
      i18n.t('success.success'),
      i18n.t('success.created_additional_package')
    );
  } catch (e) {
    toast.error(
      i18n.t('error.error'),
      i18n.t('error.error_create_additional_package')
    );
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* createNewPlan(action) {
  try {
    yield put(commonLoadingStart());

    const { payload } = action;

    const { data } = yield call(
      api.post,
      'v3/companies/createNewPlan',
      payload
    );

    yield put(actions.createNewPlanSuccess(data));
    toast.success(i18n.t('success.success'), i18n.t('success.created_plan'));
  } catch (e) {
    toast.error(i18n.t('error.error'), i18n.t('error.error_update_plan'));
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* fetchOrganizations(action) {
  try {
    const params = action.payload;

    const { data } = yield call(api.get, 'v3/companies/organization', {
      params,
    });

    yield put(actions.fetchOrganizationsSuccess(data));
  } catch (e) {
    toast.error(
      i18n.t('error.error'),
      i18n.t('error.error_fetch_organizations')
    );
  }
}

export function* fetchCompanyHistoryActions(action) {
  yield put(commonLoadingStart());

  try {
    const {
      companyId,
      limit,
      page,
      startDate,
      endDate,
      users,
      actions: userActions,
      orgIndicators,
    } = action.payload;

    const myParams = {
      limit,
      startDate,
      endDate,
      users,
      actions: userActions,
      orgIndicators,
    };

    const url = `v3/companies/historyActions/${companyId}`;
    let params = `page=${page}`;

    Object.entries(myParams).forEach(([key, value]) => {
      if (value) params += `&${key}=${value}`;
    });

    const { data } = yield call(api.get, `${url}?${params}`);

    yield put(actions.fetchCompanyHistoryActionsSuccess(data));
  } catch (error) {
    toast.error(i18n.t('error.error'), i18n.t('error.error_fetch_audit'));
  } finally {
    yield put(commonLoadingFinish());
  }
}

export default all([
  takeLatest(types.FETCH_COMPANIES, fetchCompanies),
  takeLatest(types.FETCH_COMPANY, fetchCompany),
  takeLatest(types.UPDATE_COMPANY, updateCompany),
  takeLatest(types.CHANGE_PLAN_COMPANY, changePlanCompany),
  takeLatest(types.CREATE_ADDITIONAL_MESSAGE, createAdditionalMessage),
  takeLatest(types.CREATE_NEW_PLAN, createNewPlan),
  takeLatest(types.FETCH_ORGANIZATIONS, fetchOrganizations),
  takeLatest(types.FETCH_COMPANY_HISTORY_ACTIONS, fetchCompanyHistoryActions),
]);
