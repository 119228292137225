import produce from 'immer';
import types from './types';

const INITIAL_STATE = {
  nps: [],
};

export default function triggers(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case types.FETCH_NPS_SUCCESS: {
        draft.nps = action.payload;
        break;
      }

      case types.UPDATE_NPS_SUCCESS: {
        const currentNps = action.payload;
        draft.nps = state.nps.map((research) =>
          research?._id === currentNps?._id ? currentNps : research
        );
        break;
      }

      case types.DELETE_NPS_SUCCESS: {
        const npsFiltered = state.nps.filter(
          (nps) => nps._id !== action.payload.npsId
        );

        draft.nps = npsFiltered;
        break;
      }

      case types.CREATE_NPS_SUCCESS: {
        const newNps = action.payload;
        const newNpsList = [...state.nps, newNps];
        draft.nps = newNpsList;
        break;
      }

      default:
    }
  });
}
