import styled from 'styled-components';

export const FullScreen = styled.div`
  display: ${({ isOpen }) => (isOpen === 1 ? 'flex' : 'none')};
  cursor: ${({ isFixed }) => isFixed === 0 && 'pointer'};

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  justify-content: center;
  align-items: center;
  z-index: ${({ zIndex }) => zIndex};
  animation: fadeIn 380ms ease-in-out 1;
  background-color: #2f3845b8;
  backdrop-filter: blur(2px);

  @keyframes fadeIn {
    from {
      background-color: #2f384521;
    }
    to {
      background-color: #2f3845b8;
    }
  }
`;

export const Modal = styled.div`
  background-color: #fff;
  border-radius: 3px;
  position: relative;
  cursor: default;
  width: 100%;
  max-width: ${({ maxWidth }) => maxWidth};
  animation: slideIn 350ms cubic-bezier(0.42, 0, 0.21, 1) 1;

  @keyframes slideIn {
    from {
      transform: translateY(-120px);
      opacity: 0;
    }

    20% {
      opacity: 0;
    }

    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
`;

export const ModalContainer = styled.div`
  width: 100%;
  overflow-y: auto;
  max-height: ${({ maxHeight }) => maxHeight};
`;

export const ModalHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #dadce3;
  padding: var(--spacement-medium);
`;

export const ModalTitle = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 150%;
  letter-spacing: 0.0075em;
  color: var(--neutral-4);
`;

export const ModalButtonClose = styled.button`
  outline: none;
  cursor: pointer;

  :hover {
    opacity: 0.7;
  }
`;

export const ModalContentWrapper = styled.div`
  width: 100%;
  overflow-y: ${({ overflowY }) => overflowY};
  max-height: ${({ maxHeight }) => maxHeight};
  padding: var(--spacement-medium);
`;

export const ModalFooterWrapper = styled.div`
  padding: var(--spacement-medium);
  background: var(--neutral-0);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: var(--spacement-medium);
`;
