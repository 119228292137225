const Types = {
  FETCH_IVR_REPORT_TEMPLATES: '@ivrReports/FETCH_IVR_REPORT_TEMPLATES',
  FETCH_IVR_REPORT_TEMPLATES_SUCCESS:
    '@ivrReports/FETCH_IVR_REPORT_TEMPLATES_SUCCESS',
  FETCH_IVR_REPORT_TEMPLATES_FAILURE:
    '@ivrReports/FETCH_IVR_REPORT_TEMPLATES_FAILURE',

  SEARCH_IVR_REPORT_TEMPLATES: '@ivrReports/SEARCH_IVR_REPORT_TEMPLATES',
  SEARCH_IVR_REPORT_TEMPLATES_SUCCESS:
    '@ivrReports/SEARCH_IVR_REPORT_TEMPLATES_SUCCESS',
  SEARCH_IVR_REPORT_TEMPLATES_FAILURE:
    '@ivrReports/SEARCH_IVR_REPORT_TEMPLATES_FAILURE',

  CREATE_IVR_REPORT_TEMPLATE: '@ivrReports/CREATE_IVR_REPORT_TEMPLATE',
  CREATE_IVR_REPORT_TEMPLATE_SUCCESS:
    '@ivrReports/CREATE_IVR_REPORT_TEMPLATE_SUCCESS',
  CREATE_IVR_REPORT_TEMPLATE_FAILURE:
    '@ivrReports/CREATE_IVR_REPORT_TEMPLATE_FAILURE',

  UPDATE_IVR_REPORT_TEMPLATE: '@ivrReports/UPDATE_IVR_REPORT_TEMPLATE',
  UPDATE_IVR_REPORT_TEMPLATE_SUCCESS:
    '@ivrReports/UPDATE_IVR_REPORT_TEMPLATE_SUCCESS',
  UPDATE_IVR_REPORT_TEMPLATE_FAILURE:
    '@ivrReports/UPDATE_IVR_REPORT_TEMPLATE_FAILURE',

  CLONE_IVR_REPORT_TEMPLATE: '@ivrReports/CLONE_IVR_REPORT_TEMPLATE',
  CLONE_IVR_REPORT_TEMPLATE_SUCCESS:
    '@ivrReports/CLONE_IVR_REPORT_TEMPLATE_SUCCESS',
  CLONE_IVR_REPORT_TEMPLATE_FAILURE:
    '@ivrReports/CLONE_IVR_REPORT_TEMPLATE_FAILURE',

  DELETE_IVR_REPORT_TEMPLATE: '@ivrReports/DELETE_IVR_REPORT_TEMPLATE',
  DELETE_IVR_REPORT_TEMPLATE_SUCCESS:
    '@ivrReports/DELETE_IVR_REPORT_TEMPLATE_SUCCESS',
  DELETE_IVR_REPORT_TEMPLATE_FAILURE:
    '@ivrReports/DELETE_IVR_REPORT_TEMPLATE_FAILURE',

  FETCH_IVR_REPORT_TEMPLATE_FIELDS:
    '@ivrReports/FETCH_IVR_REPORT_TEMPLATE_FIELDS',
  FETCH_IVR_REPORT_TEMPLATE_FIELDS_SUCCESS:
    '@ivrReports/FETCH_IVR_REPORT_TEMPLATE_FIELDS_SUCCESS',
  FETCH_IVR_REPORT_TEMPLATE_FIELDS_FAILURE:
    '@ivrReports/FETCH_IVR_REPORT_TEMPLATE_FIELDS_FAILURE',

  SEARCH_IVR_REPORT_CALLS: '@ivrReports/SEARCH_IVR_REPORT_CALLS',
  SEARCH_IVR_REPORT_CALLS_SUCCESS:
    '@ivrReports/SEARCH_IVR_REPORT_CALLS_SUCCESS',
  SEARCH_IVR_REPORT_CALLS_FAILURE:
    '@ivrReports/SEARCH_IVR_REPORT_CALLS_FAILURE',
  RESET_SEARCH_IVR_REPORT_CALLS: '@ivrReports/RESET_SEARCH_IVR_REPORT_CALLS',

  GENERATE_IVR_REPORT_CALLS: '@ivrReports/GENERATE_IVR_REPORT_CALLS',
  GENERATE_IVR_REPORT_CALLS_SUCCESS:
    '@ivrReports/GENERATE_IVR_REPORT_CALLS_SUCCESS',
  GENERATE_IVR_REPORT_CALLS_FAILURE:
    '@ivrReports/GENERATE_IVR_REPORT_CALLS_FAILURE',

  SEARCH_IVR_REPORTS: '@ivrReports/SEARCH_IVR_REPORTS',
  SEARCH_IVR_REPORTS_SUCCESS: '@ivrReports/SEARCH_IVR_REPORTS_SUCCESS',
  SEARCH_IVR_REPORTS_FAILURE: '@ivrReports/SEARCH_IVR_REPORTS_FAILURE',

  DELETE_IVR_REPORT: '@ivrReports/DELETE_IVR_REPORT',
  DELETE_IVR_REPORT_SUCCESS: '@ivrReports/DELETE_IVR_REPORT_SUCCESS',
  DELETE_IVR_REPORT_FAILURE: '@ivrReports/DELETE_IVR_REPORT_FAILURE',

  DOWNLOAD_IVR_REPORT: '@ivrReports/DOWNLOAD_IVR_REPORT',
  DOWNLOAD_IVR_REPORT_SUCCESS: '@ivrReports/DOWNLOAD_IVR_REPORT_SUCCESS',
  DOWNLOAD_IVR_REPORT_FAILURE: '@ivrReports/DOWNLOAD_IVR_REPORT_FAILURE',

  SEARCH_IVR_REPORT_DASHBOARD: '@ivrReports/SEARCH_IVR_REPORT_DASHBOARD',
  SEARCH_IVR_REPORT_DASHBOARD_SUCCESS:
    '@ivrReports/SEARCH_IVR_REPORT_DASHBOARD_SUCCESS',
  SEARCH_IVR_REPORT_DASHBOARD_FAILURE:
    '@ivrReports/SEARCH_IVR_REPORT_DASHBOARD_FAILURE',
};

export default Types;
