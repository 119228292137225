const Types = {
  FETCH_BOTS_REQUEST: '@bots/FETCH_BOTS_REQUEST',
  FETCH_BOTS_SUCCESS: '@bots/FETCH_BOTS_SUCCESS',

  FETCH_BOTS_BY_COMPANY_REQUEST: '@bots/FETCH_BOTS_BY_COMPANY_REQUEST',
  FETCH_BOTS_BY_COMPANY_SUCCESS: '@bots/FETCH_BOTS_BY_COMPANY_SUCCESS',

  FETCH_BOT_REQUEST: '@bots/FETCH_BOT_REQUEST',
  FETCH_BOT_SUCCESS: '@bots/FETCH_BOT_SUCCESS',

  FETCH_COLLECT_DATA_COUNT: '@bots/FETCH_COLLECT_DATA_COUNT',
  FETCH_COLLECT_DATA_COUNT_SUCCESS: '@bots/FETCH_COLLECT_DATA_COUNT_SUCCESS',

  FETCH_BOT_COLLECT_DATA_CSV: '@bots/FETCH_BOT_COLLECT_DATA_CSV',

  CREATE_REQUEST: '@bots/CREATE_REQUEST',
  CREATE_SUCCESS: '@bots/CREATE_SUCCESS',

  DELETE_REQUEST: '@bots/DELETE_REQUEST',
  DELETE_SUCCESS: '@bots/DELETE_SUCCESS',

  UPDATE_BOT_REQUEST: '@bots/UPDATE_BOT_REQUEST',
  UPDATE_BOT_SUCCESS: '@bots/UPDATE_BOT_SUCCESS',

  CLONE_REQUEST: '@bots/CLONE_REQUEST',
  CLONE_REQUEST_SUCCESS: '@bots/CLONE_REQUEST_SUCCESS',

  UPDATE_LAST_UPDATE_REQUEST: '@bots/UPDATE_LAST_UPDATE_REQUEST',
  UPDATE_LAST_UPDATE_SUCCESS: '@bots/UPDATE_LAST_UPDATE_SUCCESS',

  UPDATE_STATUS_REQUEST: '@bots/UPDATE_STATUS_REQUEST',
  UPDATE_STATUS_REQUEST_SUCCESS: '@bots/UPDATE_STATUS_REQUEST_SUCCESS',

  UPDATE_BOT_GENERAL_REQUEST: '@bot/UPDATE_BOT_GENERAL_REQUEST',

  UPDATE_JIRA_SETTINGS_REQUEST: '@bot/UPDATE_JIRA_SETTINGS_REQUEST',

  UPDATE_GLPI_SETTINGS: '@bot/UPDATE_GLPI_SETTINGS',

  UPDATE_ZENDESK_SETTINGS_REQUEST: '@bot/UPDATE_ZENDESK_SETTINGS_REQUEST',

  RETRIEVE_ATTENDANCE_HOUR_SETTINGS: '@bot/RETRIEVE_ATTENDANCE_HOUR_SETTINGS',

  UPDATE_DAYS_OFF_SETTINGS: '@bot/UPDATE_DAYS_OFF_SETTINGS',
  RETRIEVE_DAYS_OFF_SETTINGS: '@bot/RETRIEVE_DAYS_OFF_SETTINGS',

  GET_PHRASEOLOGY_REQUEST: '@bots/GET_PHRASEOLOGY_REQUEST',
  GET_PHRASEOLOGY_SUCCESS: '@bots/GET_PHRASEOLOGY_SUCCESS',

  UPDATE_PHRASEOLOGY_REQUEST: '@bots/UPDATE_PHRASEOLOGY_REQUEST',

  GET_VARIABLES_REQUEST: '@bots/GET_VARIABLES_REQUEST',
  GET_VARIABLES_SUCCESS: '@bots/GET_VARIABLES_SUCCESS',

  PUBLISH_BOT_REQUEST: '@bots/PUBLISH_BOT_REQUEST',
  PUBLISH_BOT_SUCCESS: '@bots/PUBLISH_BOT_SUCCESS',
  PUBLISH_BOT_FAILED: '@bots/PUBLISH_BOT_FAILED',

  CHANGE_STAGING_AREA_REQUEST: '@bots/CHANGE_STAGING_AREA_REQUEST',
  CHANGE_STAGING_AREA_SUCCESS: '@bots/CHANGE_STAGING_AREA_SUCCESS',
  CHANGE_STAGING_AREA_FAILED: '@bots/CHANGE_STAGING_AREA_FAILED',

  GET_BOT_HUMAN_ATTENDANCE_QUEUES: '@bots/GET_BOT_HUMAN_ATTENDANCE_QUEUES',
  GET_BOT_HUMAN_ATTENDANCE_QUEUES_SUCCESS:
    '@bots/GET_BOT_HUMAN_ATTENDANCE_QUEUES_SUCCESS',

  SET_OPENED_MODAL: '@bots/SET_OPENED_MODAL',
  SET_OPENED_MODAL_SUGGESTION: '@bots/SET_OPENED_MODAL_SUGGESTION',
  GET_SESSIONS: '@bots/GET_SESSIONS',
  GET_SESSIONS_SUCCESS: '@bots/GET_SESSIONS_SUCCESS',
  GET_SESSION_BY_ID: '@bots/GET_SESSION_BY_ID',
  GET_SESSION_BY_ID_SUCCESS: '@bots/GET_SESSION_BY_ID_SUCCESS',
  GET_MESSAGES_SESSIONS: '@bots/GET_MESSAGES_SESSIONS',
  GET_MESSAGES_SESSIONS_SUCCESS: '@bots/GET_MESSAGES_SESSIONS_SUCCESS',

  SET_OPENED_MODAL_BOT: '@bots/SET_OPENED_MODAL_BOT',

  ACTIVE_BOT_LOADING_START: '@bots/ACTIVE_BOT_LOADING_START',
  ACTIVE_BOT_LOADING_FINISH: '@bots/ACTIVE_BOT_LOADING_FINISH',

  SET_PREVIEW_IS_OPEN: '@bots/PREVIEW_IS_OPEN',

  UPDATE_QUICK_ACCESS: '@bots/UPDATE_QUICK_ACCESS',
  UPDATE_QUICK_ACCESS_SUCCESS: '@bots/UPDATE_QUICK_ACCESS_SUCCESS',

  GET_HOLIDAYS: '@bots/GET_HOLIDAYS',
  GET_HOLIDAYS_SUCESS: '@bots/GET_HOLIDAYS_SUCESS',

  FETCH_BUILDER_TRACKING: '@bots/FETCH_BUILDER_TRACKING',
  FETCH_BUILDER_TRACKING_SUCCESS: '@bots/FETCH_BUILDER_TRACKING_SUCCESS',

  FETCH_BUILDER_TRACKING_DETAILS: '@bots/FETCH_BUILDER_TRACKING_DETAILS',
  FETCH_BUILDER_TRACKING_DETAILS_SUCCESS:
    '@bots/FETCH_BUILDER_TRACKING_DETAILS_SUCCESS',

  CLONE_IVR_SUCCESS: '@ivr/CLONE_IVR_SUCCESS',

  SET_BUILDER_TRACKING_DETAILS: '@bots/SET_BUILDER_TRACKING_DETAILS',
  UPDATE_VTEX_SETTINGS: '@bots/UPDATE_VTEX_SETTINGS',

  FETCH_BOTS_WITH_SHAREABLE_IA: '@bots/FETCH_BOTS_WITH_SHAREABLE_IA',

  FETCH_BOTS_WITH_SHAREABLE_IA_SUCCESS:
    '@bots/FETCH_BOTS_WITH_SHAREABLE_IA_SUCCESS',
  FETCH_BUILDER_PENDINGS: '@bots/FETCH_BUILDER_PENDINGS',
  FETCH_BUILDER_PENDINGS_SUCCESS: '@bots/FETCH_BUILDER_PENDINGS_SUCCESS',

  RETURN_BOT: '@items/RETURN_BOT',
  RETURN_BOT_SUCCESS: '@items/RETURN_BOT_SUCCESS',
  UPDATE_CHATGPT_SETTINGS: '@bots/UPDATE_CHATGPT_SETTINGS',

  FETCH_BEHAVIOR_PENDINGS: '@bot/FETCH_BEHAVIOR_PENDINGS_TRAKING',
  FETCH_BEHAVIOR_PENDINGS_SUCCESS:
    '@bot/FETCH_BEHAVIOR_PENDINGS_TRAKING_SUCCESS',

  SET_MESSAGE_COMMENT_REQUEST: '@bo/SET_MESSAGE_COMMENT_REQUEST',
  SET_MESSAGE_COMMENT_SUCCESS: '@bo/SET_MESSAGE_COMMENT_SUCCESS',
  SET_MESSAGE_FAVORITE_REQUEST: '@bo/SET_MESSAGE_FAVORITE_REQUEST',
  SET_MESSAGE_FAVORITE_SUCCESS: '@bo/SET_MESSAGE_FAVORITE_SUCCESS',

  REMOVE_MESSAGE_COMMENT_REQUEST: '@bo/REMOVE_MESSAGE_REQUEST',
  REMOVE_MESSAGE_COMMENT_SUCCESS: '@bo/REMOVE_MESSAGE_SUCCESS',

  SET_CUSTOM_CHANNEL_API_KEY: '@bot/SET_CUSTOM_CHANNEL_API_KEY',
  SET_CUSTOM_CHANNEL_API_KEY_SUCCESS: '@bot/SET_CUSTOM_CHANNEL_API_KEY_SUCCESS',

  VIEW_CUSTOM_CHANNEL_API_KEY: '@bot/VIEW_CUSTOM_CHANNEL_API_KEY',
  VIEW_CUSTOM_CHANNEL_API_KEY_SUCCESS:
    '@bot/VIEW_CUSTOM_CHANNEL_API_KEY_SUCCESS',

  DELETE_CUSTOM_CHANNEL_API_KEY: '@bot/DELETE_CUSTOM_CHANNEL_API_KEY',
  DELETE_CUSTOM_CHANNEL_API_KEY_SUCCESS:
    '@bot/DELETE_CUSTOM_CHANNEL_API_KEY_SUCCESS',
  UPDATE_CHANNEL_REQUEST: '@bot/UPDATE_CHANNEL_REQUEST',
  UPDATE_CHANNEL_SUCCESS: '@bot/UPDATE_CHANNEL_SUCCESS',
  DELETE_CHANNEL_REQUEST: '@bot/DELETE_CHANNEL_REQUEST',
  DELETE_CHANNEL_SUCCESS: '@bot/DELETE_CHANNEL_SUCCESS',

  UPDATE_HUMAN_ATTENDANCE_REQUEST: '@bot/UPDATE_HUMAN_ATTENDANCE_REQUEST',

  UPDATE_IDENTITY_REQUEST: '@bot/UPDATE_IDENTITY_REQUEST',
  UPDATE_ATTENDANCE_HOUR_SETTINGS: '@bot/UPDATE_ATTENDANCE_HOUR_SETTINGS',

  UPDATE_INTEGRATION_REQUEST: '@bot/UPDATE_INTEGRATION_REQUEST',
};

export default Types;
