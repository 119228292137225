import React from 'react';
import PropTypes from 'prop-types';

import { ModalContentWrapper } from './styles';

const ModalContent = ({ children, maxHeight, overflowY, ...rest }) => {
  return (
    <ModalContentWrapper maxHeight={maxHeight} overflowY={overflowY} {...rest}>
      {children}
    </ModalContentWrapper>
  );
};

ModalContent.propTypes = {
  children: PropTypes.node.isRequired,
  maxHeight: PropTypes.string,
  overflowY: PropTypes.string,
};

ModalContent.defaultProps = {
  maxHeight: 'max-content',
  overflowY: 'initial',
};

export default ModalContent;
