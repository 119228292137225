import { takeLatest, call, put, all, select } from 'redux-saga/effects';

import api from '~/services/api';
import { toast } from '~/components/CustomToast';
import i18n from '../../../translations/i18n';

import {
  commonLoadingStart,
  commonLoadingFinish,
  commonChangingFinish,
} from '../common/actions';
import * as actions from './actions';
import * as authActions from '../auth/actions';
import types from './types';

export function* fetchUsers({ payload }) {
  try {
    yield put(commonLoadingStart());

    const { search } = payload;
    const response = yield call(api.get, `/v3/users`, {
      params: { term: search },
    });

    yield put(actions.fetchUsersSuccess({ users: response.data }));
  } catch ({ response }) {
    toast.error(i18n.t('error.error'), i18n.t('error.error_list_users'));
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* fetchUser({ payload }) {
  try {
    yield put(commonLoadingStart());

    const { id } = payload;

    const response = yield call(api.get, `/v3/users/${id}`);

    yield put(actions.fetchUserSuccess({ user: response.data }));
  } catch ({ response }) {
    toast.error(i18n.t('error.error'), i18n.t('error.error_find_users'));
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* createUser({ payload }) {
  try {
    yield put(commonLoadingStart());

    const { user, callback } = payload;

    const { data } = yield call(api.post, `/v3/admins/create`, user);

    yield put(actions.createUserSuccess({ user: data }));
    toast.success(
      i18n.t('success.success'),
      i18n.t('success.success_create_user')
    );
    if (callback) {
      callback({ success: true });
    }
    yield put(commonChangingFinish());
  } catch ({ response }) {
    console.log();
    if (response.data.message === 'User already exists in this company') {
      toast.error(
        i18n.t('error.error'),
        i18n.t('error.error_create_users_already_exists')
      );
    } else {
      toast.error(i18n.t('error.error'), i18n.t('error.error_create_users'));
    }
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* updateUser({ payload }) {
  try {
    yield put(commonLoadingStart());

    const { user, callback } = payload;

    const { data } = yield call(api.put, `/v3/users/update/${user._id}`, user);

    yield put(actions.updateUserSuccess({ user: data }));

    const loggUser = yield select((state) => state.auth.loggedUser);

    if (loggUser._id === user._id) {
      yield put(
        authActions.updateUserProfileSuccess({
          loggedUser: {
            ...loggUser,
            ...data,
          },
        })
      );
    }

    if (callback) {
      callback({ success: true });
    }
    yield put(commonChangingFinish());
  } catch ({ response }) {
    toast.error(i18n.t('error.error'), i18n.t('error.user_update_user'));
  } finally {
    yield put(commonLoadingFinish());
  }
}

export default all([
  takeLatest(types.FETCH_USERS, fetchUsers),
  takeLatest(types.FETCH_USER, fetchUser),
  takeLatest(types.CREATE_USER, createUser),
  takeLatest(types.UPDATE_USER, updateUser),
]);
