import React, { lazy, Suspense, useEffect, useState } from 'react';
import { Switch, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import LoadingBar from '~/components/LoadingBar';
import AuthLayout from '~/pages/_layouts/auth';
import DefaultLayout from '~/pages/_layouts/default';
import history from '~/services/history';

import CustomRoute from './CustomRoute';
import ChangeRouteConfirmation from './ChangeRouteConfirmation';
import {
  setIsViewerRedator,
  setIsUserViewerRedator,
  setIsViewer,
} from '~/store/modules/session/actions';
import useNetworkVerification from '~/utils/useNetworkVerification';
import StaticError from '~/pages/StaticError';

const SignUp = lazy(() => import('../pages/auth/SignUp'));
const SignUpSuccess = lazy(() => import('../pages/auth/SignUp/Success'));
const SignUpConfirm = lazy(() => import('../pages/auth/SignUp/Confirm'));

const ResetPassword = lazy(() => import('../pages/auth/ResetPassword'));
const CreatePassword = lazy(() => import('../pages/auth/CreatePassword'));
const RecoverEmail = lazy(() => import('../pages/auth/RecoverEmail'));
const RecoverEmailSuccess = lazy(() =>
  import('../pages/auth/RecoverEmail/Success')
);

const SignIn = lazy(() => import('~/pages/auth/SignIn'));
const Tfa = lazy(() => import('~/pages/auth/Tfa'));
const TfaActivate = lazy(() => import('~/pages/auth/TfaActivate'));
const SelectCompanyAndOrganization = lazy(() =>
  import('~/pages/auth/SelectCompanyAndOrganization')
);

const RecoverPassword = lazy(() => import('~/pages/auth/RecoverPassword'));
const RecoverPasswordSuccess = lazy(() =>
  import('../pages/auth/RecoverPassword/Success')
);

const Video = lazy(() => import('~/pages/Video'));

const Ivrs = lazy(() => import('~/pages/Ivrs'));
const IvrReportsRoutes = lazy(() => import('~/pages/IvrReports/index.routes'));

const Bots = lazy(() => import('~/pages/Bots'));
const BotBuilder = lazy(() => import('~/pages/Bots/Bot/Builder'));
const CompanyReportsRoutes = lazy(() =>
  import('~/pages/CompanyReports/index.routes')
);
const PreferenceRoutes = lazy(() => import('~/pages/Preferences/index.routes'));
const BotReportsRoutes = lazy(() => import('~/pages/BotReports/index.routes'));
const ProfileRoutes = lazy(() => import('~/pages/Profile/index.routes'));
const NewsRoutes = lazy(() => import('~/pages/News'));

const Home = lazy(() => import('../pages/Home'));

const PrivacyPolicy = lazy(() => import('~/pages/PrivacyPolicy'));
const ViewMessagesExpanded = lazy(() =>
  import('~/pages/BotReports/History/components/ViewMessagesExpanded')
);

export default function Routes({ userConfirmation }) {
  const { token, loggedUser } = useSelector((state) => state.auth);
  const [isClickNextOrDown, setIsClickNextOrDown] = useState(false);
  const histo = useHistory();
  const location = useLocation();

  const dispatch = useDispatch();
  useNetworkVerification();

  useEffect(() => {
    dispatch(
      setIsUserViewerRedator(
        ['user', 'viewer', 'redator'].includes(
          loggedUser?.selectedCompany?.claim?.name?.toLowerCase()
        )
      )
    );
    dispatch(
      setIsViewerRedator(
        ['viewer', 'redator'].includes(
          loggedUser?.selectedCompany?.claim?.name?.toLowerCase()
        )
      )
    );
    dispatch(
      setIsViewer(
        loggedUser?.selectedCompany?.claim?.name?.toLowerCase() === 'viewer'
      )
    );
    if (token && loggedUser) {
      const shouldActivateTfa =
        loggedUser?.selectedCompany?.tfa &&
        !loggedUser?.tfa?.active &&
        loggedUser?.isSelectCompany &&
        !loggedUser?.isTfaLogged;

      if (shouldActivateTfa) {
        history.push('/tfa-activate');
      }

      const shouldIsTfaLogged =
        loggedUser?.tfa?.active &&
        loggedUser?.isSelectCompany &&
        !loggedUser?.isTfaLogged;

      if (shouldIsTfaLogged) {
        history.push('/tfa');
      }

      if (loggedUser?.selectedCompany && location.pathname === '/') {
        histo.replace('/bots');
        return null;
      }
    }
  }, [
    token,
    loggedUser?.selectedCompany,
    loggedUser?.tfa?.active,
    loggedUser?.claim,
    isClickNextOrDown,
  ]);

  useEffect(() => {
    const unlisten = histo.listen((_, action) => {
      if (action === 'POP') {
        setIsClickNextOrDown((prev) => !prev);
      }
    });
    return () => {
      unlisten();
    };
  }, [history]);

  return (
    <Suspense fallback={<LoadingBar />}>
      <Switch>
        <CustomRoute path="/" exact component={SignIn} layout={AuthLayout} />
        <CustomRoute path="/privacy-policy" exact component={PrivacyPolicy} />
        <CustomRoute
          path="/recover-email"
          exact
          component={RecoverEmail}
          layout={AuthLayout}
        />
        <CustomRoute
          path="/recover-email/success"
          exact
          component={RecoverEmailSuccess}
          layout={AuthLayout}
        />
        <CustomRoute
          path="/recover-password"
          exact
          component={RecoverPassword}
          layout={AuthLayout}
        />
        <CustomRoute
          path="/recover-password/success"
          exact
          component={RecoverPasswordSuccess}
          layout={AuthLayout}
        />
        <CustomRoute
          path="/reset-password/:email/:token"
          exact
          component={ResetPassword}
          layout={AuthLayout}
        />
        <CustomRoute
          path="/password/create/:token"
          exact
          component={CreatePassword}
          layout={AuthLayout}
        />
        <CustomRoute
          path="/signup"
          exact
          component={SignUp}
          layout={AuthLayout}
        />
        <CustomRoute
          path="/signup/success"
          exact
          component={SignUpSuccess}
          layout={AuthLayout}
        />
        <CustomRoute
          path="/confirm"
          exact
          component={SignUpConfirm}
          layout={AuthLayout}
        />
        <CustomRoute path="/tfa" component={Tfa} layout={AuthLayout} exact />
        <CustomRoute
          path="/tfa-activate"
          component={TfaActivate}
          layout={AuthLayout}
          exact
        />
        <CustomRoute
          path="/select-company-organization"
          component={SelectCompanyAndOrganization}
          layout={AuthLayout}
          isPrivate
        />
        <CustomRoute
          path="/home"
          exact
          component={Home}
          layout={DefaultLayout}
          isPrivate
        />
        <CustomRoute
          path="/bots"
          component={Bots}
          layout={DefaultLayout}
          isPrivate
        />
        <CustomRoute
          path="/ivrs"
          component={Ivrs}
          layout={DefaultLayout}
          isPrivate
        />
        <CustomRoute
          path="/ivr-reports"
          component={IvrReportsRoutes}
          layout={DefaultLayout}
          isPrivate
          auth={['admin', 'root', 'redator', 'user']}
        />
        <CustomRoute
          path="/ivr/:botId/builder"
          exact
          component={BotBuilder}
          isPrivate
        />
        <CustomRoute
          path="/ivr/:botId/builder/subflow/:subflowId"
          exact
          component={BotBuilder}
          isPrivate
        />
        <CustomRoute
          path="/bot/:botId/builder"
          exact
          component={BotBuilder}
          isPrivate
        />
        <CustomRoute
          path="/bot/:botId/builder/subflow/:subflowId"
          exact
          component={BotBuilder}
          isPrivate
        />
        <CustomRoute
          path="/preferences"
          component={PreferenceRoutes}
          layout={DefaultLayout}
          isPrivate
          auth={['admin', 'root']}
        />
        <CustomRoute
          path="/bot-reports"
          component={BotReportsRoutes}
          layout={DefaultLayout}
          isPrivate
        />
        <CustomRoute
          path="/company-reports"
          component={CompanyReportsRoutes}
          layout={DefaultLayout}
          isPrivate
          auth={['admin', 'root']}
        />
        <CustomRoute
          path="/news"
          component={NewsRoutes}
          layout={DefaultLayout}
          isPrivate
        />
        <CustomRoute
          path="/profile"
          component={ProfileRoutes}
          layout={DefaultLayout}
          isPrivate
        />
        <CustomRoute
          path="/video"
          exact
          component={Video}
          layout={DefaultLayout}
        />
        <CustomRoute
          path="/expanded-view-messages/:sessionId"
          exact
          component={ViewMessagesExpanded}
          isPrivate
        />
        <CustomRoute path="/static-error" exact component={StaticError} />
      </Switch>

      <ChangeRouteConfirmation userConfirmation={userConfirmation} />
    </Suspense>
  );
}
