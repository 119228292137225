import { takeLatest, call, put, all, select } from 'redux-saga/effects';
import moment from 'moment';

import i18n from '../../../translations/i18n';
import api from '~/services/api';
import { toast } from '~/components/CustomToast';

import { commonLoadingStart, commonLoadingFinish } from '../common/actions';

import * as actions from './actions';
import * as botActions from '../bots/actions';

import types from './types';

export function* getCompanyBotsCountRequest(action) {
  const { companyId, token, orgIndicators } = action.payload;

  try {
    yield put(commonLoadingStart());
    const { data } = yield call(
      api.get,
      `/v3/dashboard/company/bots/count?company=${companyId}&token=${token}&orgIndicators=${orgIndicators}`
    );
    const total = data[0]?.count;
    yield put(actions.getCompanyBotsCountSuccess(total));
  } catch (error) {
    toast.error(
      i18n.t('error.error'),
      i18n.t('error.error_getting_bots_count')
    );
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* getCompanyUsersCountRequest(action) {
  const { companyId, token, orgIndicators } = action.payload;
  try {
    yield put(commonLoadingStart());
    const { data } = yield call(
      api.get,
      `/v3/dashboard/company/users/count?company=${companyId}&token=${token}&orgIndicators=${orgIndicators}`
    );
    const total = data[0]?.count;
    yield put(actions.getCompanyUsersCountSuccess(total));
  } catch (error) {
    toast.error(
      i18n.t('error.error'),
      i18n.t('error.error_getting_users_count')
    );
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* getCompanySectorsCountRequest(action) {
  const { companyId, token, orgIndicators } = action.payload;
  try {
    yield put(commonLoadingStart());
    const { data } = yield call(
      api.get,
      `/v3/dashboard/company/sectors/count?company=${companyId}&token=${token}&orgIndicators=${orgIndicators}`
    );
    const total = data[0]?.count;
    yield put(actions.getCompanySectorsCountSuccess(total));
  } catch (error) {
    toast.error(
      i18n.t('error.error'),
      i18n.t('error.error_getting_sectors_count')
    );
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* getMyDashboardRequest(action) {
  const { userId, token } = action.payload;
  try {
    yield put(commonLoadingStart());
    const { data } = yield call(
      api.get,
      `/v3/dashboard/?user_id=${userId}&token=${token}`
    );
    yield put(actions.createMyDashboardSuccess(data));
  } catch ({ response }) {
    if (response.status !== 404) {
      toast.error(i18n.t('error.error_getting_dashboard'));
    }
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* createMyDashboardRequest(action) {
  const { token } = action.payload;
  try {
    yield put(commonLoadingStart());
    const { data } = yield call(api.post, `/v3/dashboard/?token=${token}`, {
      ...action.payload,
    });
    toast.success(
      i18n.t('success.success'),
      i18n.t('success.success_create_dashboard')
    );
    yield put(actions.createMyDashboardSuccess(data));
  } catch (error) {
    toast.error(
      i18n.t('error.error'),
      i18n.t('error.error_creating_dashboard')
    );
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* updateMyDashboardRequest(action) {
  const { token, dashboardId } = action.payload;
  try {
    yield put(commonLoadingStart());
    const { data } = yield call(
      api.put,
      `/v3/dashboard/${dashboardId}?token=${token}`,
      {
        ...action.payload,
      }
    );
    toast.success(
      i18n.t('success.success'),
      i18n.t('success.success_update_dashboard')
    );
    yield put(actions.updateMyDashboardSuccess(data));
  } catch (error) {
    toast.error(
      i18n.t('error.error'),
      i18n.t('error.error_updating_dashboard')
    );
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* getListErrorLog(action) {
  const { url } = action.payload;
  try {
    yield put(commonLoadingStart());
    const { data } = yield call(api.get, `/v3/bots/item-log?${url}`);
    yield put(actions.successListErrorLog(data));
  } catch (error) {
    toast.error(i18n.t('error.error'), i18n.t('error.error_getting_log_error'));
  } finally {
    yield put(commonLoadingFinish());
  }
}

function saveAs(blob, filename) {
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = filename;
  link.click();
  URL.revokeObjectURL(url);
}
export function* exportListErrosLog(action) {
  const { url } = action.payload;

  try {
    yield put(actions.loadingDownloadListErrorLog(true));

    const { data: dataExport } = yield call(
      api.get,
      `/v3/bots/export-item-log?${url}`,
      {
        responseType: 'blob',
      }
    );

    const dataLog = yield select((s) => s.reports?.listErrorLog);

    if (dataLog.total > 1000) {
      toast.warn(
        i18n.t('warning.warning_notice'),
        i18n.t('warning.warning_limit_report')
      );
    }

    const file = new Blob([dataExport], {
      type: 'application/vnd.ms-excel',
    });
    saveAs(file, `export-error-log.xlsx`);
  } catch (error) {
    toast.error(i18n.t('error.error'), i18n.t('error.error_getting_log_error'));
  } finally {
    yield put(actions.loadingDownloadListErrorLog(false));
  }
}

const addEndpointBotParameters = (
  startDate,
  endDate,
  botId,
  token,
  tags = [],
  channels = [],
  variables = [],
  timezone,
  orgIndicators = [],
  showSmsEmail
) => {
  let endpoint = `?bot=${botId}&token=${token}`;
  endpoint += `&start_date=${startDate}`;
  endpoint += `&end_date=${endDate}`;
  endpoint += `&timezone=${timezone}`;
  if (tags.length !== 0) {
    endpoint += `&tags=${encodeURIComponent(JSON.stringify(tags))}`;
  }

  if (channels.length !== 0) {
    endpoint += `&channels=${encodeURIComponent(JSON.stringify(channels))}`;
  }

  if (variables.length !== 0) {
    endpoint += `&variables=${encodeURIComponent(JSON.stringify(variables))}`;
  }

  if (orgIndicators.length !== 0) {
    endpoint += `&orgIndicators=${encodeURIComponent(
      JSON.stringify(orgIndicators)
    )}`;
  }

  endpoint += `&showSmsEmail=${showSmsEmail}`;

  return endpoint;
};

const addEndpointCompanyParameters = (
  startDate,
  endDate,
  companyId,
  token,
  timezone,
  orgIndicators,
  showSmsEmail
) => {
  let endpoint = `?company=${companyId}&token=${token}`;
  endpoint += `&start_date=${startDate}`;
  endpoint += `&end_date=${endDate}`;
  endpoint += `&timezone=${timezone}`;
  endpoint += `&orgIndicators=${orgIndicators}`;
  endpoint += `&showSmsEmail=${showSmsEmail}`;

  return endpoint;
};

export function* getBotSessionTags(action) {
  const {
    botId,
    token,
    startDate,
    endDate,
    nameTags,
    channels,
    variables,
    timezone,
    orgIndicators,
  } = action.payload;

  try {
    yield put(commonLoadingStart());
    const url = `/v3/dashboard/tags${addEndpointBotParameters(
      startDate,
      endDate,
      botId,
      token,
      nameTags,
      channels,
      variables,
      timezone,
      orgIndicators
    )}`;

    const { data } = yield call(api.get, url);
    yield put(actions.getBotSessionTagsSuccess(data));
  } catch (error) {
    toast.error(
      i18n.t('error.error'),
      i18n.t('bot_reports.tags.error.tags_error')
    );
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* getCompanyMessages(action) {
  const params = {
    startDate: action.payload.start_date,
    endDate: action.payload.end_date,
    companyId: action.payload.companyId,
    token: action.payload.token,
    timezone: action.payload.timezone,
    orgIndicators: action.payload.orgIndicators,
    showSmsEmail: action.payload.showSmsEmail,
  };

  try {
    yield put(commonLoadingStart());

    const { data } = yield call(
      api.get,
      `v3/dashboard/company/messages${addEndpointCompanyParameters(
        params.startDate,
        params.endDate,
        params.companyId,
        params.token,
        params.timezone,
        params.orgIndicators,
        params.showSmsEmail
      )}`
    );

    yield put(actions.getCompanyMessagesSuccess(data));
  } catch (error) {
    toast.error(i18n.t('error.error'), i18n.t('error.error_getting_messages'));
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* getCompanySessionsRequest(action) {
  const params = {
    startDate: action.payload.start_date,
    endDate: action.payload.end_date,
    companyId: action.payload.companyId,
    token: action.payload.token,
    timezone: action.payload.timezone,
    orgIndicators: action.payload.orgIndicators,
  };

  try {
    yield put(commonLoadingStart());
    const { data } = yield call(
      api.get,
      `/v3/dashboard/company/sessions${addEndpointCompanyParameters(
        params.startDate,
        params.endDate,
        params.companyId,
        params.token,
        params.timezone,
        params.orgIndicators
      )}`
    );

    yield put(actions.getCompanySessionsSuccess(data));
  } catch (error) {
    toast.error(
      i18n.t('error.error'),
      i18n.t('error.error_getting_sessions_company')
    );
  } finally {
    yield put(commonLoadingFinish());
  }
}

const onPeriodInvalid = (startDate, endDate) => {
  const isInvalid = moment(startDate).isAfter(endDate);
  if (isInvalid) {
    toast.error(
      i18n.t('error.error'),
      i18n.t('error.error_start_date_before_end')
    );
  }
  return isInvalid;
};

export function* getBotMessages(action) {
  const { payload } = action;
  try {
    yield put(commonLoadingStart());

    const { data } = yield call(
      api.get,
      `v3/dashboard/bots/messages${addEndpointBotParameters(
        payload.startDate,
        payload.endDate,
        payload.botId,
        payload.token,
        payload.tags,
        payload.channels,
        payload.variables,
        payload.timezone,
        payload.orgIndicators,
        payload.showSmsEmail
      )}`
    );

    yield put(actions.getBotMessagesSuccess(data));
  } catch (error) {
    toast.error(
      i18n.t('error.error'),
      i18n.t('error.error_getting_bot_messages')
    );
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* generateBotCustomReport(action) {
  const params = action.payload;
  const token = params.token;
  const botId = params.bot;

  const url = `v3/report/messages/generate?token=${token}&bot=${botId}`;

  try {
    yield put(commonLoadingStart());
    yield call(api.post, url, {
      ...params,
    });
    toast.success(i18n.t('success.success_create_bot_custom_report'));
    yield put(botActions.fetchBotRequest({ id: botId }));
  } catch (e) {
    toast.error(
      i18n.t('error.error'),
      i18n.t('error.error_generating_custom_report')
    );
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* getBotResume(action) {
  const { payload } = action;
  try {
    yield put(commonLoadingStart());

    const { data } = yield call(
      api.get,
      `v3/dashboard/bots/resume?bot=${payload.botId}&token=${payload.token}`
    );

    yield put(actions.getBotResumeSuccess(data || {}));
  } catch (error) {
    toast.error(
      i18n.t('error.error'),
      i18n.t('error.error_getting_bot_summary')
    );
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* getNotUnderstoodIntentions(action) {
  const { botId, params } = action.payload;

  const endpoint = 'v3/dashboard/ia/not-understood';
  const url = `${endpoint}/${botId}?${params}`;

  try {
    yield put(commonLoadingStart());
    const { data } = yield call(api.get, url);

    yield put(actions.getNotUnderstoodIntentionsSuccess(data));
  } catch (error) {
    toast.error(
      i18n.t('error.error'),
      i18n.t('error.error_not_understood_intentions')
    );
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* getIntentionsPerformance(action) {
  const { botId, params } = action.payload;

  const endpoint = 'v3/dashboard/ia/intention-performance';
  const url = `${endpoint}/${botId}?${params}`;

  try {
    yield put(commonLoadingStart());
    const { data } = yield call(api.get, url);

    yield put(actions.getIntentionsPerformanceSuccess(data));
  } catch (error) {
    toast.error(
      i18n.t('error.error'),
      i18n.t('error.error_not_understood_intentions')
    );
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* getUnderstoodIntentions(action) {
  const { botId, params } = action.payload;

  const endpoint = 'v3/dashboard/ia/understood';
  const url = `${endpoint}/${botId}?${params}`;

  try {
    yield put(commonLoadingStart());
    const { data } = yield call(api.get, url);

    yield put(actions.getUnderstoodIntentionsSuccess(data));
  } catch (error) {
    toast.error(
      i18n.t('error.error'),
      i18n.t('error.error_not_understood_intentions')
    );
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* getReportSessionGenerate(action) {
  const params = action.payload;
  const token = params.token;

  const url = `v3/report/session/generate?token=${token}`;

  try {
    yield put(commonLoadingStart());
    const { data } = yield call(api.post, url, {
      ...params,
    });
    yield put(actions.getReportSessionGenerateSuccess(data));
    toast.success(
      i18n.t('success.success'),
      i18n.t('reports.history.message-report-toaster')
    );
  } catch (e) {
    toast.error(
      i18n.t('error.error'),
      i18n.t('error.error_generating_custom_report')
    );
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* getBotSessionRequest(action) {
  const {
    botId,
    token,
    startDate,
    endDate,
    tags,
    channels,
    variables,
    timezone,
    orgIndicators,
  } = action.payload;
  const isInvalid = onPeriodInvalid(startDate, endDate);

  if (isInvalid) return;

  try {
    yield put(commonLoadingStart());

    const url = `/v3/dashboard/bots/sessions${addEndpointBotParameters(
      startDate,
      endDate,
      botId,
      token,
      tags,
      channels,
      variables,
      timezone,
      orgIndicators
    )}`;

    const { data } = yield call(api, url);
    yield put(actions.getBotSessionSuccess(data));
  } catch (e) {
    toast.error(
      i18n.t('error.error'),
      i18n.t('error.error_generating_custom_report')
    );
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* getNpsReport(action) {
  const {
    npsId,
    botId,
    token,
    startDate,
    endDate,
    tags,
    channels,
    variables,
    timezone,
    onlyFinished,
    orgIndicators,
  } = action.payload;

  const isInvalid = onPeriodInvalid(startDate, endDate);

  if (isInvalid) return;

  try {
    yield put(commonLoadingStart());

    const url = `/v3/dashboard/nps${addEndpointBotParameters(
      startDate,
      endDate,
      botId,
      token,
      tags,
      channels,
      variables,
      timezone,
      orgIndicators
    )}&npsId=${npsId}&onlyFinished=${onlyFinished}`;

    const { data } = yield call(api, url);
    yield put(actions.getNpsReportSuccess(data));
  } catch (e) {
    toast.error(
      i18n.t('error.error'),
      i18n.t('error.error_generating_nps_report')
    );
  } finally {
    yield put(commonLoadingFinish());
  }
}

export default all([
  takeLatest(types.GET_COMPANY_BOTS_COUNT_REQUEST, getCompanyBotsCountRequest),
  takeLatest(
    types.GET_COMPANY_USERS_COUNT_REQUEST,
    getCompanyUsersCountRequest
  ),
  takeLatest(
    types.GET_COMPANY_SECTORS_COUNT_REQUEST,
    getCompanySectorsCountRequest
  ),
  takeLatest(types.GET_MY_DASHBOARD_REQUEST, getMyDashboardRequest),
  takeLatest(types.CREATE_MY_DASHBOARD_REQUEST, createMyDashboardRequest),
  takeLatest(types.UPDATE_MY_DASHBOARD_REQUEST, updateMyDashboardRequest),
  takeLatest(types.GET_LIST_ERROR_LOG, getListErrorLog),
  takeLatest(types.GET_COMPANY_MESSAGES_REQUEST, getCompanyMessages),
  takeLatest(types.GET_COMPANY_SESSIONS_REQUEST, getCompanySessionsRequest),
  takeLatest(types.GET_BOT_SESSION_TAGS, getBotSessionTags),
  takeLatest(types.GET_BOT_MESSAGES_REQUEST, getBotMessages),
  takeLatest(types.GENERATE_BOT_CUSTOM_REPORT, generateBotCustomReport),
  takeLatest(types.GET_BOT_RESUME, getBotResume),
  takeLatest(types.GET_NOT_UNDERSTOOD_INTENTIONS, getNotUnderstoodIntentions),
  takeLatest(types.GET_INTENTIONS_PERFORMANCE, getIntentionsPerformance),
  takeLatest(types.GET_UNDERSTOOD_INTENTIONS, getUnderstoodIntentions),
  takeLatest(types.GET_REPORT_SESSION_GENERATE, getReportSessionGenerate),
  takeLatest(types.GET_BOT_SESSION_REPORT, getBotSessionRequest),
  takeLatest(types.EXPORT_GET_LIST_ERROR_LOG, exportListErrosLog),
  takeLatest(types.GET_NPS_REPORT_REQUEST, getNpsReport),
]);
