const Types = {
  FETCH_IVR_PLANS: '@ivrPlans/FETCH_IVR_PLANS',
  FETCH_IVR_PLANS_SUCCESS: '@ivrPlans/FETCH_IVR_PLANS_SUCCESS',
  FETCH_IVR_PLANS_FAILURE: '@ivrPlans/FETCH_IVR_PLANS_FAILURE',

  SEARCH_IVR_PLAN_HISTORY: '@ivrPlans/SEARCH_IVR_PLAN_HISTORY',
  SEARCH_IVR_PLAN_HISTORY_SUCCESS: '@ivrPlans/SEARCH_IVR_PLAN_HISTORY_SUCCESS',
  SEARCH_IVR_PLAN_HISTORY_FAILURE: '@ivrPlans/SEARCH_IVR_PLAN_HISTORY_FAILURE',

  SELECT_IVR_PLAN: '@ivrPlans/SELECT_IVR_PLAN',
  SELECT_IVR_PLAN_SUCCESS: '@ivrPlans/SELECT_IVR_PLAN_SUCCESS',
  SELECT_IVR_PLAN_FAILURE: '@ivrPlans/SELECT_IVR_PLAN_FAILURE',

  FETCH_IVR_PLAN_CONSUMPTION: '@ivrPlans/FETCH_IVR_PLAN_CONSUMPTION',
  FETCH_IVR_PLAN_CONSUMPTION_SUCCESS:
    '@ivrPlans/FETCH_IVR_PLAN_CONSUMPTION_SUCCESS',
  FETCH_IVR_PLAN_CONSUMPTION_FAILURE:
    '@ivrPlans/FETCH_IVR_PLAN_CONSUMPTION_FAILURE',
};

export default Types;
