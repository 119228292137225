import types from './types';

export const fetchBotsRequest = () => ({
  type: types.FETCH_BOTS_REQUEST,
});

export const fetchBotsSuccess = ({ bots }) => ({
  type: types.FETCH_BOTS_SUCCESS,
  payload: { bots },
});

export const fetchBotsByCompanyRequest = () => ({
  type: types.FETCH_BOTS_BY_COMPANY_REQUEST,
});

export const fetchBotsByCompanySuccess = ({ bots }) => ({
  type: types.FETCH_BOTS_BY_COMPANY_SUCCESS,
  payload: { bots },
});

export const fetchBotRequest = (payload) => ({
  type: types.FETCH_BOT_REQUEST,
  payload,
});

export const fetchBotSuccess = ({ bot }) => ({
  type: types.FETCH_BOT_SUCCESS,
  payload: { bot },
});

export const fetchBotCollectDataCount = ({
  botId,
  dashboard_token,
  startDate,
  endDate,
}) => ({
  type: types.FETCH_COLLECT_DATA_COUNT,
  payload: { botId, dashboard_token, startDate, endDate },
});

export const fetchBotCollectDataCountSuccess = ({ data }) => ({
  type: types.FETCH_COLLECT_DATA_COUNT_SUCCESS,
  payload: { data },
});

export const fetchBotCollectDataCsv = ({
  botId,
  dashboard_token,
  startDate,
  endDate,
}) => ({
  type: types.FETCH_BOT_COLLECT_DATA_CSV,
  payload: { botId, dashboard_token, startDate, endDate },
});

export const createBotRequest = (payload, callback) => ({
  type: types.CREATE_REQUEST,
  payload,
  callback,
});
export const createBotSuccess = ({ newBot }) => ({
  type: types.CREATE_SUCCESS,
  payload: { newBot },
});

export const deleteBotRequest = ({ id }, callback) => ({
  type: types.DELETE_REQUEST,
  payload: { id, callback },
});
export const deleteBotSuccess = ({ id }) => ({
  type: types.DELETE_SUCCESS,
  payload: { id },
});

export const cloneBotRequest = ({
  uuid,
  title,
  description,
  organizationId,
  companyId,
  copyAI,
}) => ({
  type: types.CLONE_REQUEST,
  payload: {
    uuid,
    title,
    description,
    organizationId,
    companyId,
    copyAI,
  },
});

export const cloneBotRequestSuccess = ({ pendingBot }) => ({
  type: types.CLONE_REQUEST_SUCCESS,
  payload: pendingBot,
});

export const updateBotStatusRequest = ({ description, isActive, botId }) => ({
  type: types.UPDATE_STATUS_REQUEST,
  payload: { description, isActive, botId },
});

export const updateBotStatusRequestSuccess = ({ isActive, botId }) => ({
  type: types.UPDATE_STATUS_REQUEST_SUCCESS,
  payload: { isActive, botId },
});

export const updateBotRequest = (
  botid,
  data,
  showToast = true,
  showLoading = false,
  updateWhatsappCallbacks = false,
  callback
) => ({
  type: types.UPDATE_BOT_REQUEST,
  payload: {
    botid,
    data,
    updateWhatsappCallbacks,
    showToast,
    showLoading,
    callback,
  },
});

export const updateBotSuccess = ({ data }) => ({
  type: types.UPDATE_BOT_SUCCESS,
  payload: {
    data,
  },
});

export const updateBotLastUpdate = ({ botId }) => ({
  type: types.UPDATE_LAST_UPDATE_REQUEST,
  payload: { botId },
});
export const updateBotLastUpdateSuccess = (payload) => ({
  type: types.UPDATE_LAST_UPDATE_SUCCESS,
  payload,
});

export const updateBotGeneralInformation = (payload, callback) => ({
  type: types.UPDATE_BOT_GENERAL_REQUEST,
  payload: { ...payload, callback },
});

export const updateJiraSettings = (botId, data) => ({
  type: types.UPDATE_JIRA_SETTINGS_REQUEST,
  botId,
  settings: data,
});

export const updateZendeskSettings = ({ botId, data }, callback) => ({
  type: types.UPDATE_ZENDESK_SETTINGS_REQUEST,
  payload: { botId, data, callback },
});

export const updateAttendanceHoursSettings = (botId, data, newSettings) => ({
  type: types.UPDATE_ATTENDANCE_HOUR_SETTINGS,
  botId,
  settings: data,
  newSettings,
});

export const updateDaysOffSettings = (botId, data) => ({
  type: types.UPDATE_DAYS_OFF_SETTINGS,
  botId,
  settings: data,
});

export const getBotPhraseologyRequest = (payload) => ({
  type: types.GET_PHRASEOLOGY_REQUEST,
  payload,
});
export const getBotPhraseologySuccess = (payload) => ({
  type: types.GET_PHRASEOLOGY_SUCCESS,
  payload,
});

export const updateBotPhraseologyRequest = (payload) => ({
  type: types.UPDATE_PHRASEOLOGY_REQUEST,
  payload,
});

export const getBotVariablesRequest = (payload) => ({
  type: types.GET_VARIABLES_REQUEST,
  payload,
});
export const getBotVariablesSuccess = (payload) => ({
  type: types.GET_VARIABLES_SUCCESS,
  payload,
});

export const changeStageAreaRequest = ({ botId, version }) => ({
  type: types.CHANGE_STAGING_AREA_REQUEST,
  payload: { botId, version },
});
export const changeStageAreaSuccess = () => ({
  type: types.CHANGE_STAGING_AREA_SUCCESS,
});
export const changeStageAreaFailed = () => ({
  type: types.CHANGE_STAGING_AREA_FAILED,
});

export const publishBotRequest = (payload) => ({
  type: types.PUBLISH_BOT_REQUEST,
  payload,
});
export const publishBotSuccess = (bot) => ({
  type: types.PUBLISH_BOT_SUCCESS,
  payload: { bot },
});
export const publishBotFailed = () => ({
  type: types.PUBLISH_BOT_FAILED,
});

export const retrieveAttendenceHoursSettings = (payload) => ({
  type: types.RETRIEVE_ATTENDANCE_HOUR_SETTINGS,
  payload,
});

export const getBotHumanAttendanceQueues = ({ botId }) => ({
  type: types.GET_BOT_HUMAN_ATTENDANCE_QUEUES,
  payload: { botId },
});

export const getBotHumanAttendanceQueuesSuccess = ({ queues }) => ({
  type: types.GET_BOT_HUMAN_ATTENDANCE_QUEUES_SUCCESS,
  payload: { queues },
});

export const setOpenedModal = (name) => ({
  type: types.SET_OPENED_MODAL,
  payload: { name },
});

export const setOpenedModalSuggestion = (name) => ({
  type: types.SET_OPENED_MODAL_SUGGESTION,
  payload: { name },
});

export const getSessionById = (payload) => ({
  type: types.GET_SESSION_BY_ID,
  payload,
});

export const getSessionByIdSuccess = (payload) => ({
  type: types.GET_SESSION_BY_ID_SUCCESS,
  payload,
});

export const getSessions = (payload) => ({
  type: types.GET_SESSIONS,
  payload,
});

export const getSessionsSuccess = (payload) => ({
  type: types.GET_SESSIONS_SUCCESS,
  payload,
});

export const getMessagesSession = (payload) => ({
  type: types.GET_MESSAGES_SESSIONS,
  payload,
});

export const getMessagesSessionSuccess = (payload) => ({
  type: types.GET_MESSAGES_SESSIONS_SUCCESS,
  payload,
});

export const updateGlpiSettings = (botId, data) => ({
  type: types.UPDATE_GLPI_SETTINGS,
  botId,
  settings: data,
});

export const updateVtexSettings = (botId, data) => ({
  type: types.UPDATE_VTEX_SETTINGS,
  botId,
  settings: data,
});

export const updateChatGptSettings = (botId, data, callback) => ({
  type: types.UPDATE_CHATGPT_SETTINGS,
  botId,
  settings: data,
  callback,
});

export const setOpenedModalBot = (name) => ({
  type: types.SET_OPENED_MODAL_BOT,
  payload: { name },
});

export const activeBotLoadingStart = () => ({
  type: types.ACTIVE_BOT_LOADING_START,
});
export const activeBotLoadingFinish = () => ({
  type: types.ACTIVE_BOT_LOADING_FINISH,
});

export const setPreviewIsOpen = (payload) => ({
  type: types.SET_PREVIEW_IS_OPEN,
  payload,
});

export const updateQuickAccess = (payload) => ({
  type: types.UPDATE_QUICK_ACCESS,
  payload,
});

export const updateQuickAccessSuccess = (payload) => ({
  type: types.UPDATE_QUICK_ACCESS_SUCCESS,
  payload,
});

export const getHolidays = (payload) => ({
  type: types.GET_HOLIDAYS,
  payload,
});

export const getHolidaysSuccess = (payload) => ({
  type: types.GET_HOLIDAYS_SUCESS,
  payload,
});

export const fetchBuilderTracking = (payload) => ({
  type: types.FETCH_BUILDER_TRACKING,
  payload,
});

export const fetchBuilderTrackingSuccess = (payload) => ({
  type: types.FETCH_BUILDER_TRACKING_SUCCESS,
  payload,
});

export const fetchBuilderTrackingDetails = (payload) => ({
  type: types.FETCH_BUILDER_TRACKING_DETAILS,
  payload,
});

export const fetchBuilderTrackingDetailsSuccess = (payload) => ({
  type: types.FETCH_BUILDER_TRACKING_DETAILS_SUCCESS,
  payload,
});

export const cloneIvrSuccess = (payload) => ({
  type: types.CLONE_IVR_SUCCESS,
  payload,
});

export const setBuilderTrackingDetails = (payload) => ({
  type: types.SET_BUILDER_TRACKING_DETAILS,
  payload,
});

export const fetchBotsWithShareableIA = (payload) => ({
  type: types.FETCH_BOTS_WITH_SHAREABLE_IA,
  payload,
});

export const fetchBotsWithShareableIASuccess = (payload) => ({
  type: types.FETCH_BOTS_WITH_SHAREABLE_IA_SUCCESS,
  payload,
});

export const fetchBuilderPendings = (payload) => ({
  type: types.FETCH_BUILDER_PENDINGS,
  payload,
});

export const fetchBuilderPendingsSuccess = (payload) => ({
  type: types.FETCH_BUILDER_PENDINGS_SUCCESS,
  payload,
});

export const returnBot = (action, callback) => ({
  type: types.RETURN_BOT,
  payload: { ...action, callback },
});

export const returnBotSuccess = (payload) => ({
  type: types.RETURN_BOT_SUCCESS,
  payload,
});

export const fetchBehaviorPendings = (payload) => ({
  type: types.FETCH_BEHAVIOR_PENDINGS,
  payload,
});

export const fetchBehaviorPendingsSuccess = (payload) => ({
  type: types.FETCH_BEHAVIOR_PENDINGS_SUCCESS,
  payload,
});

export const fetchBehaviorPendingsDetails = (payload) => ({
  type: types.FETCH_BEHAVIOR_PENDINGS_DETAILS,
  payload,
});

export const setMessageFavoriteRequest = (payload) => ({
  type: types.SET_MESSAGE_FAVORITE_REQUEST,
  payload,
});
export const setMessageFavoriteSuccess = (payload) => ({
  type: types.SET_MESSAGE_FAVORITE_SUCCESS,
  payload,
});

export const setMessageCommentRequest = (payload) => ({
  type: types.SET_MESSAGE_COMMENT_REQUEST,
  payload,
});
export const setMessageCommentSuccess = (payload) => ({
  type: types.SET_MESSAGE_COMMENT_SUCCESS,
  payload,
});

export const removeMessageCommentRequest = (payload) => ({
  type: types.REMOVE_MESSAGE_COMMENT_REQUEST,
  payload,
});
export const removeMessageCommentSuccess = (payload) => ({
  type: types.REMOVE_MESSAGE_COMMENT_SUCCESS,
  payload,
});

export const setCustomChannelApiKey = (botId) => ({
  type: types.SET_CUSTOM_CHANNEL_API_KEY,
  payload: botId,
});

export const setCustomChannelApiKeySuccess = (payload) => ({
  type: types.SET_CUSTOM_CHANNEL_API_KEY_SUCCESS,
  payload,
});

export const viewCustomChannelApiKey = (botId) => ({
  type: types.VIEW_CUSTOM_CHANNEL_API_KEY,
  payload: botId,
});

export const viewCustomChannelApiKeySuccess = (payload) => ({
  type: types.VIEW_CUSTOM_CHANNEL_API_KEY_SUCCESS,
  payload,
});

export const deleteCustomChannelApiKey = (botId) => ({
  type: types.DELETE_CUSTOM_CHANNEL_API_KEY,
  payload: botId,
});

export const deleteCustomChannelApiKeySuccess = () => ({
  type: types.DELETE_CUSTOM_CHANNEL_API_KEY_SUCCESS,
});

export const updateChannel = (payload) => ({
  type: types.UPDATE_CHANNEL_REQUEST,
  payload,
});

export const deleteChannel = (payload) => ({
  type: types.DELETE_CHANNEL_REQUEST,
  payload,
});

export const updateHumanAttendance = (payload) => ({
  type: types.UPDATE_HUMAN_ATTENDANCE_REQUEST,
  payload,
});

export const updateIdentity = (payload) => ({
  type: types.UPDATE_IDENTITY_REQUEST,
  payload,
});

export const updateIntegration = (payload) => ({
  type: types.UPDATE_INTEGRATION_REQUEST,
  payload,
});
