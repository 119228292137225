import { takeLatest, call, put, all, fork } from 'redux-saga/effects';

import api from '../../../services/api';
import * as actions from './actions';
import types from './types';

import {
  fetchItemsSuccess,
  fetchSubflowItemsSuccess,
  // fetchAllItemsSuccess,
  fetchResumedAllItemsSuccess,
} from '../items/actions';
import {
  fetchConnectorsSuccess,
  fetchSubflowConnectorsSuccess,
  // fetchAllConnectorsSuccess,
} from '../connectors/actions';
import { fetchBotSuccess } from '../bots/actions';
// import { listTagsSuccess } from '../tags/actions';
// import { listVariablesSuccess } from '../variables/actions';
// import { fetchBotSuccess } from '../bots/actions';

// function* fetchBot({ botId }) {
//   const { data } = yield call(api.get, `/v3/bots/${botId}`);
//   yield put(fetchBotSuccess({ bot: data }));
// }

function* fetchItems({ botId }) {
  const { data } = yield call(api.get, `/v3/bots/${botId}/items`);
  yield put(fetchItemsSuccess({ items: data?.items || [] }));
  yield put(fetchBotSuccess({ bot: data?.bot }));
}

function* fetchSubflowItems({ botId, subflowId }) {
  const { data } = yield call(
    api.get,
    `/v3/bots/${botId}/items-subflow/${subflowId}`
  );
  yield put(
    fetchSubflowItemsSuccess({
      items: data?.items || [],
      subflowPath: data?.path || [],
    })
  );
  yield put(fetchBotSuccess({ bot: data?.bot }));
}

function* fetchResumedAllItems({ botId }) {
  const { data } = yield call(api.get, `/v3/bots/${botId}/resumed-all-items`);
  yield put(fetchResumedAllItemsSuccess(data));
}

// function* fetchAllItems({ botId }) {
//   const { data } = yield call(api.get, `/v3/bots/${botId}/all-items`);
//   yield put(fetchAllItemsSuccess({ allItems: data }));
// }

function* fetchConnectors({ botId }) {
  const { data } = yield call(api.get, `/v3/bots/${botId}/connectors`);
  yield put(fetchConnectorsSuccess({ connectors: data?.connectors || [] }));
}

function* fetchSubflowConnectors({ botId, subflowId }) {
  const { data } = yield call(
    api.get,
    `/v3/bots/${botId}/connectors-subflow/${subflowId}`
  );
  yield put(
    fetchSubflowConnectorsSuccess({ connectors: data?.connectors || [] })
  );
}

// function* fetchAllConnectors({ botId }) {
//   const { data } = yield call(api.get, `/v3/bots/${botId}/all-connectors`);
//   yield put(fetchAllConnectorsSuccess({ allConnectors: data }));
// }

// function* fetchTags({ botId }) {
//   const { data } = yield call(api.get, `/v3/bots/tag/${botId}`);
//   yield put(listTagsSuccess(data));
// }

// function* fetchVariables({ botId }) {
//   const { data } = yield call(api.get, `/v3/bots/variable/${botId}`);
//   yield put(listVariablesSuccess(data));
// }

function* fetchAll({ payload }) {
  const { botId, subflowId } = payload;

  if (subflowId) {
    yield all([
      fork(fetchSubflowItems, { botId, subflowId }),
      fork(fetchSubflowConnectors, { botId, subflowId }),
      fork(fetchResumedAllItems, { botId }),
    ]);
  } else {
    yield all([
      fork(fetchItems, { botId }),
      fork(fetchConnectors, { botId }),
      fork(fetchResumedAllItems, { botId }),
    ]);
  }

  // yield fork(fetchBot, { botId });
  // yield fork(fetchAllItems, { botId });
  // yield fork(fetchAllConnectors, { botId });
  // yield fork(fetchTags, { botId });
  // yield fork(fetchVariables, { botId });
}

function* main(action) {
  try {
    yield call(fetchAll, action);
    yield put(actions.fetchDataSuccess());
  } catch (e) {
    yield put(actions.fetchDataFailure());
  }
}

export default all([takeLatest(types.FETCH_DATA_START, main)]);
