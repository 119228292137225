import { takeLatest, call, put, all } from 'redux-saga/effects';

import api from '~/services/api';
import types from './types';

import {
  commonLoadingStart,
  commonLoadingFinish,
  commonChangingFinish,
} from '../common/actions';
import * as actions from './actions';

export function* validateTopdeskSettingsRequest({ payload }) {
  try {
    yield put(commonLoadingStart());

    const { botId } = payload;

    const { data } = yield call(api.get, `v3/bots/${botId}/topdesk/validate`);
    yield put(commonChangingFinish());

    if (data) {
      yield put(actions.validateTopdeskSettingsSuccess(data));
    }
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* getTopdeskStatusesRequest({ payload }) {
  try {
    yield put(commonLoadingStart());

    const { botId } = payload;

    const { data } = yield call(
      api.get,
      `v3/bots/${botId}/topdesk/getStatusList`
    );
    yield put(commonChangingFinish());

    if (data) {
      yield put(actions.getTopdeskStatusesSuccess(data));
    }
  } finally {
    yield put(commonLoadingFinish());
  }
}

export default all([
  takeLatest(
    types.VALIDATE_TOPDESK_SETTINGS_REQUEST,
    validateTopdeskSettingsRequest
  ),
  takeLatest(types.GET_TOPDESK_STATUSES_REQUEST, getTopdeskStatusesRequest),
]);
