import { setupCache } from 'axios-cache-adapter';
import localforage from 'localforage';
import memoryDriver from 'localforage-memoryStorageDriver';

export const URL_CACHED_$ = [
  /\/resumed-all-items$/,
  /\/items$/,
  /\/connectors$/,
  /\/variable\/[a-zA-Z0-9_-]+$/,
  /\/tag\/[a-zA-Z0-9_-]+$/,
  /\/items-subflow\/[a-zA-Z0-9_-]+$/,
  /\/connectors-subflow\/[a-zA-Z0-9_-]+$/,
];

export function getURLCached(req) {
  const { url } = req;
  const urlMapped = URL_CACHED_$.map((pattern) => {
    const patternRegexp = new RegExp(pattern);
    return patternRegexp.test(url);
  });
  const shouldCached = urlMapped.includes(true);
  return !shouldCached;
}

export const generateCacheInvalidation = async (config, request) => {
  if (request.clearCacheEntry && request.clearCacheEntry?.length !== 0) {
    const cacheKeys = await config.store.keys();

    await Promise.all(
      cacheKeys.map(async (key) => {
        if (request.clearCacheEntry.some((pattern) => key.includes(pattern))) {
          await config.store.removeItem(key);
        }
      })
    );
  }
};

localforage.defineDriver(memoryDriver);
const localforageStore = localforage.createInstance({
  driver: [localforage.LOCALSTORAGE, localforage.INDEXEDDB],
  name: 'BOT_API_CACHE',
});

export const removeStoragePrefixWithCache = async () => {
  try {
    if ('caches' in window) {
      await localforageStore.clear();

      const cacheNames = await caches.keys();
      await Promise.all(cacheNames.map((cache) => caches.delete(cache)));
      console.log('Caches do navegador limpos com sucesso');
    }

    if (window.gc) {
      window.gc();
      console.log('Coleta de lixo forçada');
    } else {
      console.warn('Coleta de lixo não disponível');
    }
  } catch (error) {
    console.error('Erro ao limpar o cache');
  }
};

const cache = setupCache({
  maxAge: 30 * 60 * 1000,
  store: localforageStore,
  exclude: {
    filter: (req) => getURLCached(req),
    query: false,
  },
  invalidate: (config, request) => generateCacheInvalidation(config, request),
});

export default cache;
