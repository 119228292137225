import types from './types';

export const fetchIvrPlans = ({ type }) => ({
  type: types.FETCH_IVR_PLANS,
  payload: { type },
});
export const fetchIvrPlansSuccess = (data) => ({
  type: types.FETCH_IVR_PLANS_SUCCESS,
  payload: data,
});
export const fetchIvrPlansFailure = () => ({
  type: types.FETCH_IVR_PLANS_SUCCESS,
});

export const searchIvrPlanHistory = (params) => ({
  type: types.SEARCH_IVR_PLAN_HISTORY,
  payload: { params },
});
export const searchIvrPlanHistorySuccess = (data) => ({
  type: types.SEARCH_IVR_PLAN_HISTORY_SUCCESS,
  payload: data,
});
export const searchIvrPlanHistoryFailure = () => ({
  type: types.SEARCH_IVR_PLAN_HISTORY_FAILURE,
});

export const selectIvrPlan = ({ id }, callback) => ({
  type: types.SELECT_IVR_PLAN,
  payload: { id, callback },
});
export const selectIvrPlanSuccess = () => ({
  type: types.SELECT_IVR_PLAN_SUCCESS,
});
export const selectIvrPlanFailure = () => ({
  type: types.SELECT_IVR_PLAN_FAILURE,
});

export const fetchIvrPlanConsumption = ({ referenceMonth }) => ({
  type: types.FETCH_IVR_PLAN_CONSUMPTION,
  payload: { referenceMonth },
});
export const fetchIvrPlanConsumptionSuccess = (data) => ({
  type: types.FETCH_IVR_PLAN_CONSUMPTION_SUCCESS,
  payload: data,
});
export const fetchIvrPlanConsumptionFailure = () => ({
  type: types.FETCH_IVR_PLAN_CONSUMPTION_FAILURE,
});
